// eslint-disable-next-line no-redeclare,no-unused-vars
function showSettings(type) {
  dashboard.show();

  // specific config
  if (type) {
    window['show' + ucfirst(type) + 'Settings']();
  }

  // develop config
  else if (config == 'develop') {
    showClientSettings();
    if (industry) showIndustrySettings();
    showGlobalSettings();
  }

  // branch config
  else {
    showBranchSettings();
    showMergedSettings();
  }
}
function showClientSettings() {
  ajax({
    object: 'settings',
    objectSub: 'settingsClient',
    func: 'buildSettingsView',
    detail: true,
    keepDetail: true,
  });
}
function showIndustrySettings() {
  ajax({
    object: 'settings',
    objectSub: 'settingsIndustry',
    func: 'buildSettingsView',
    detail: true,
    keepDetail: true,
  });
}
function showGlobalSettings() {
  ajax({
    object: 'settings',
    objectSub: 'settingsGlobal',
    func: 'buildSettingsView',
    detail: true,
    keepDetail: true,
  });
}
function showBranchSettings() {
  ajax({
    object: 'settings',
    send: {func: 'buildConfigTree'},
    func: 'buildSettingsView',
    detail: true,
    keepDetail: true,
    title: config,
    keepFunc: true,
  });
}
function showMergedSettings() {
  ajax({
    object: 'settings',
    send: {func: 'buildConfigTree', merged: true},
    func: 'buildSettingsView',
    detail: true,
    keepDetail: true,
    title: config + '.json merged',
    keepFunc: true,
  });
}
